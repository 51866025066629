import imageInput from '@/mixins/image-input.js';
import BlogsPostModel from '../blogs/models/blogs-post.model';
import {mapState} from 'vuex';

export default {
    data() {
        return {
            item: {
                active: false
            }
        }
    },
    methods: {
        approve() {
            let data = new BlogsPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("blogs/setItem", formData).then((res) => {
                if (res) {
                    this.item = {
                        active: false
                    };
                    this.resfresh = true;
                }
            });
        }
    },
    computed: {
        ...mapState({
            categories: (state) => state.categories.items,
        }),
    },
    mixins: [imageInput],
}
